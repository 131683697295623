.img-center {
	margin-left: auto;
	margin-right: auto;
}

.row-centered {
	text-align: center;
}

.col-centered {
	display: inline-block;
	float: none !important;
	text-align: left;
	vertical-align: top;
}
