/*!
  * driveway - pure CSS masonry layout aid
  *
  * @license MIT
  * @author jh3y
*/
.dw {
	box-sizing: border-box;
	position: relative;
	column-gap: 2rem;
	column-fill: initial;
}

.dw * {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

@media (min-width: 768px) {
	.dw {
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
	}
}

@media (min-width: 992px) {
	.dw {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;
	}
}

@media (min-width: 1600px) {
	.dw {
		-webkit-column-count: 4;
		-moz-column-count: 4;
		column-count: 4;
	}
}

.dw-panel {
	margin: 0;
}
