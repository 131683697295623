.card-img-top {
	width: 100%;
}

.card-title {
	margin-bottom: 1rem;
	
	+ .card-description {
		margin-top: .25rem;
	}
	
}

.card-subtitle {
	margin-top: 0;
}

.card-description {
	> *:last-child {
		margin-bottom: 0;
	}
}

.card-outer-wrapper {
	margin-bottom: -2rem !important;
}

.card-bottom-outer-wrapper {
	margin-bottom: -$main-padding-y !important;
	background-color: $brand-primary;
	
	//@media (max-width: 1920px) {
	//	.row > .col-sm-6 {
	//		.post.card-xlg.paging {
	//
	//			.card-links-list .card-links-item {
	//				display: none;
	//
	//				&:first-child {
	//					display: list-item;
	//					max-width: 100%;
	//					flex: 0 0 100%;
	//				}
	//
	//			}
	//
	//		}
	//	}
	//}
	
	@media (max-width: 1199px) {
		.row > .col-sm-6 {
			flex: 0 0 100%;
			max-width: 100%;
		}
		
		.post.card-xlg.paging {
			
			.card-links-list .card-links-item {
				display: none;
				
				&:first-child {
					display: list-item;
				}
				
			}
			
		}
		
	}
	
	@media (max-width: 991px) {
		margin-bottom: -$main-padding-y-sm !important;
		
		.post.card-xlg.paging {
			.card-image-header-content {
				text-align: center;
			}
		}
		
	}
	
}

.main-card {
	border: none;
	background-color: transparent;
	box-shadow: $cards-box-shadow;
	
	&.gallery-page {
		box-shadow: none;
	}
	
	&.events-pages, &.paging, &.posts {
		overflow: hidden;
	}
	
	&.post {
		&.old {
			max-height: inherit;
			
			.card-image-header:before {
				padding-top: 0;
			}
			
			.card-image-header-content {
				position: relative;
				text-align: center;
			}
			
			.card-image-header-content {
				padding: 5rem 2rem;
				
				@media (max-width: 575px) {
					padding: 3rem 1rem;
				}
				
			}
		}
	}
	
	&.paging {
		height: 100%;
		box-shadow: none;
		border-radius: 0;
		
		.card-image-header, .card-background {
			border-radius: 0;
		}
		
		&.card-type-old .card-image-header:before {
			padding-top: calc(60% + 3.5rem);
			
			@media (min-width: 850px) and (max-width: 1199px) {
				padding-top: 45%;
			}
			@media (max-width: 849px) {
				padding-top: 0;
			}
			
		}
		
	}
	
	.card-background {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		border-radius: $border-radius-small;
		
		&.blur {
			filter: blur(.35rem);
			transform: scale(1.1);
		}
		
		&.lazy-loaded {
			opacity: .6;
			
			~ .card-image-header-content, ~ .card-image-header-type {
				opacity: 1;
				transition-delay: .225s;
			}
			
		}
		
	}
	
	.text-white-light {
		color: rgba(255, 255, 255, .75);
		font-weight: bold !important;
		
		p {
			font-weight: bold;
			transition: color .45s ease;
		}
		
		a {
			color: rgba(255, 255, 255, .75);
			transition: color .45s ease;
			
			&:hover {
				color: #FFFFFF;
			}
			
			&:focus {
				outline-color: $brand-info !important;
			}
			
		}
		
	}
	
	.card-background-gradient {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: $brand-primary-hover;
		background: linear-gradient(135deg, $brand-primary-hover, $brand-primary 45%, $brand-info-active);
		border-radius: $border-radius-small;
		
		~ .card-image-header-content, ~ .card-image-header-type {
			opacity: 1;
			transition-delay: .225s;
		}
		
	}
	
	.card-link {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
		border-radius: $border-radius-small;
		
		&:focus {
			box-shadow: inset 0 0 0 .35rem $brand-primary;
		}
		
	}
	
	.card-list-link, .events-tags-item {
		position: relative;
		z-index: 3;
	}
	
	.card-image-header {
		overflow: hidden;
		position: relative;
		background-color: #000000;
		border-radius: $border-radius-small;
		transform: scale(1);
		
		&:before {
			position: relative;
			content: '';
			display: block;
			padding-top: 100%;
			padding-left: 100%;
			border-radius: $border-radius-small;
			background-color: #000000;
		}
		
		.card-title {
			margin-bottom: 0;
			color: #FFFFFF;
			
			a {
				color: #FFFFFF;
			}
			
		}
		
		.card-subtitle {
			color: $site-header-nav-normal-color;
			text-transform: uppercase;
			margin-bottom: 0;
			margin-top: .25rem;
			font-weight: bold;
			font-size: 1rem;
		}
		
		.card-description {
			color: #FFFFFF;
			font-weight: 600;
			
			a {
				color: #FFFFFF;
			}
			
		}
		
	}
	
	.card-image-header-type {
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: $site-header-nav-normal-color;
		line-height: 0;
		transition: .9s opacity ease;
		opacity: 0;
	}
	
	.events-tags {
		margin-bottom: -.5rem !important;
		
		.events-tags-item {
			margin-bottom: .5rem;
			font-size: .85rem;
			line-height: 1.25rem;
			
			a {
				display: block;
			}
			
		}
		
	}
	
	.card-links-list {
		background-color: $brand-primary;
		width: 100%;
		margin: 0 !important;
		position: relative;
		text-align: center;
		
		.card-links-item {
			padding: 0;
			margin: 0;
			height: 100%;
			max-height: 3.5rem;
		}
		
		.card-list-link {
			width: 100%;
			height: 100%;
			display: flex;
			padding: 1rem 1.5rem;
			-webkit-box-align: center !important;
			-webkit-align-items: center !important;
			-moz-box-align: center !important;
			-ms-flex-align: center !important;
			align-items: center !important;
			border: none;
			border-radius: 0;
			max-height: 3.5rem;
		}
		
		.card-list-link-middle {
			width: 100%;
		}
		
	}
	
	.card-content {
		padding: 1rem 0 0;
	}
	
	&.card-had-links {
		&:hover {
			.card-image-header-links {
				opacity: 1;
				visibility: visible;
			}
			.card-image-header-content, .card-background {
				filter: blur(.35rem);
				transition-delay: 0s;
			}
		}
	}
	
	.card-image-header-links {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, .75);
		padding: 1.5rem;
		opacity: 0;
		visibility: hidden;
		transition-property: opacity, visibility;
		transition-duration: .45s;
		transition-timing-function: ease;
		
		ul {
			width: 100%;
		}
		
		li {
			margin-bottom: .5rem;
			overflow: hidden;
			
			&:last-child {
				margin-bottom: 0;
			}
			
		}
		
		a {
			display: block;
			white-space: normal;
			vertical-align: baseline;
		}
		
	}
	
	.card-image-header-content {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		font-weight: 600;
		transition: opacity, visibility, filter;
		transition-duration: .9s, .9s, .45s;
		transition-timing-function: ease;
		opacity: 0;
		background: linear-gradient(transparent, rgba(0, 0, 0, .35));
		border-radius: $border-radius-small;
		
		> p:last-child > br:last-child {
			display: none;
		}
		
		&.top {
			top: 0;
			bottom: inherit;
			background: linear-gradient(rgba(0, 0, 0, .35), transparent);
		}
		
		&.bottom {
			bottom: 0;
		}
		
		&.text-md {
			font-size: 1.15rem;
			
			p {
				font-size: 1.15rem;
			}
			
		}
		
	}
	
	&.events-pages {
		
		&.card-type-article, &.card-type-links {
			.card-image-header, .card-background-gradient, .card-background, .card-image-header:before {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		
		.card-links-list {
			border-bottom-right-radius: $border-radius-small;
			border-bottom-left-radius: $border-radius-small;
			
			.card-links-item {
				&:first-child {
					border-bottom-left-radius: $border-radius-small;
					
					.card-list-link {
						border-bottom-left-radius: $border-radius-small;
					}
					
				}
				&:last-child {
					border-bottom-right-radius: $border-radius-small;
					
					.card-list-link {
						border-bottom-right-radius: $border-radius-small;
					}
					
				}
			}
			
		}
	}
	
	&.card-xlg {
		//max-height: 40rem;
		
		.card-image-header-content {
			padding: 2rem;
			
			@media (min-width: 992px) and (max-width: 1199px) {
				padding: 1.5rem;
			}
			
		}
		
		.card-image-header-type {
			font-size: 2.5rem;
		}
		
	}
	
	&.card-lg {
		
		.card-image-header-content {
			padding: 1.5rem;
		}
		
		.card-image-header-type {
			font-size: 2.5rem;
		}
		
	}
	
	&.card-sm {
		
		.card-image-header-content {
			padding: 1rem;
		}
		
		.card-image-header-type {
			font-size: 2rem;
		}
		
	}
	
	&.card-smlg-height .card-image-header:before {
		padding-top: 70%;
	}
	
	&.card-sm-height .card-image-header:before {
		padding-top: 60%;
	}
	
	&.card-xsm-height .card-image-header:before {
		padding-top: 40%;
	}
	
	//&.card-xlg .card-image-header:before {
	//	 padding-top: 55%;
	// }
	
	.card-image-header:hover {
		
		.card-links-background {
			opacity: .9;
			visibility: visible;
		}
		
		.card-links-wrapper {
			transform: scale(1) translateY(0);
			opacity: 1;
			visibility: visible;
		}
		
	}
	
	&.card-hasLink {
		
		.card-image-header {
			
			.card-background {
				transform: scale(1);
				transition-duration: .9s;
				transition-timing-function: ease;
				transition-property: opacity, transform, -webkit-transform, -moz-transform, -o-transform;
			}
			
			.card-image-header-content {
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			
			&:hover {
				.card-background {
					opacity: .75;
					transform: scale(1.15);
				}
				
				.text-white-light {
					color: #FFFFFF;
					
					a {
						color: #FFFFFF;
					}
					
				}
				
			}
			
		}
		
		&.card-blured {
			
			.card-background.blur {
				transform: scale(1.1);
			}
			
			&:hover {
				.card-background.blur {
					transform: scale(1.25);
				}
			}
			
		}
		
	}
	@media (min-width: 1476px) {
		&.card-type-gallery.gallery {
			
			.card-title {
				line-height: 2rem;
				font-size: $font-size-h3;
			}
			
			.card-image-header-content {
				padding: 1.25rem;
			}
			
			.card-image-header-content .lead {
				font-size: 1.15rem;
			}
			
		}
	}
	
	@media (min-width: 992px) and (max-width: 1199px) {
		&.posts.card-width-4, &.card-type-gallery.gallery, &.search {
			.card-title {
				line-height: 2rem;
				font-size: $font-size-h3;
			}
		}
		&.card-type-gallery.gallery {
			
			.card-image-header-content {
				padding: 1.25rem;
			}
			
			.card-image-header-content .lead {
				font-size: 1.15rem;
			}
			
		}
	}
	
	@media (max-width: 875px) and (min-width: 767px) {
		&.card-type-gallery.gallery {
			.card-image-header:before {
				padding-top: 75%;
			}
		}
	}
	
	@media (min-width: 850px) and (max-width: 1199px) {
		&.post {
			
			.card-image-header:before {
				padding-top: 75%;
			}
			
			.card-description {
				margin-bottom: 1rem !important;
			}
			
			&.card-xlg, &.card-xlg.paging {
				
				.card-description {
					margin-bottom: 0 !important;
				}
				
			}
			
			&.card-xlg.paging {
				
				.card-image-header:before {
					padding-top: 45%;
				}
				
			}
			
		}
		
	}
	
	@media (max-width: 849px) {
		&.post {
			max-height: inherit;
			
			.card-image-header:before {
				padding-top: 0;
			}
			
			.card-image-header-content {
				position: relative;
				text-align: center;
			}
			
			.card-image-header-content {
				padding: 3rem 2rem;
			}
			
			&.card-xlg.paging {
				.card-image-header-content {
					padding: 6rem 2rem;
				}
			}
			
		}
		
	}
	
	@media (max-width: 767px) {
		&.post {
			.card-image-header-content {
				text-align: center;
			}
		}
	}
	
	@media (max-width: 575px) {
		
		.card-image-header:before {
			padding-top: 65%;
		}
		
		&.post {
			
			.card-image-header:before {
				padding-top: 0;
			}
			
			.card-image-header-content {
				padding: 3rem 1rem;
			}
			
			.card-links-list .card-links-item {
				display: none;
				
				&:first-child {
					display: list-item;
				}
				
			}
			
			&.card-xlg.paging {
				.card-image-header-content {
					padding: 6rem 2rem;
				}
			}
			
		}
		
	}
	
	@media (max-width: 475px) {
		
		.card-image-header:before {
			padding-top: 75%;
		}
		
	}
	
	@media (max-width: 400px) {
		&.post {
			
			&.card-xlg.paging {
				.card-image-header-content {
					padding: 4rem 1rem;
				}
			}
			
			&.events-pages.card-xlg, &.card-xlg.paging {
				.events-tags-item {
					overflow: hidden;
					white-space: normal;
					
					.card-list-link {
						display: block;
						overflow: hidden;
						white-space: normal;
					}
					
				}
				
				.events-tags + .events-description {
					margin-top: 1rem !important;
				}
				
			}
			
		}
		
		.card-image-header:before {
			padding-top: 100%;
		}
		
		&.post {
			.card-image-header:before {
				padding-top: 0;
			}
		}
		
		&.events-pages.card-xlg, &.card-xlg.paging {
			.events-tags-item {
				width: 100%;
				display: block;
			}
		}
		
	}
	
	@media (max-width: 325px) {
		&.content-top-bottom {
			.card-image-header:before {
				padding-top: 0;
			}
			
			.card-image-header-content {
				position: relative;
				text-align: center;
				padding: 4rem 2rem;
				
				a {
					display: block;
				}
				
				&.top {
					padding-top: 0;
				}
				
				&.bottom {
					padding-bottom: 1rem;
				}
				
			}
		}
		
		&.content-bottom-top {
			.card-image-header:before {
				padding-top: 0;
			}
			
			.card-image-header-content {
				position: relative;
				text-align: center;
				padding: 4rem 2rem;
				
				a {
					display: block;
				}
				
				&.top {
					padding-bottom: 1rem;
				}
				
				&.bottom {
					padding-top: 0;
				}
				
			}
		}
		
	}
	
	@media (min-width: 1476px) {
		&.post.card-xlg.paging .card-image-header {
			max-height: 45rem;
			
		}
	}
	
	@media (max-width: 1199px) {
		&.post.card-xlg.paging {
			
			.events-tags-contact {
				display: none;
			}
			
		}
		
	}
	
}

.card-small {
	@media (min-width: 992px) and (max-width: 1199px) {
		flex: 0 0 50%;
		max-width: 50%;
	}
}
