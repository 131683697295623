//@import "components/fancybox/src/css/core";
//@import "components/fancybox/src/css/fullscreen";
//@import "components/fancybox/src/css/slideshow";
//@import "components/fancybox/src/css/thumbs"

////////////////////////////////////////////////////////

.fancybox-caption-wrap, .fancybox-infobar, .fancybox-toolbar {
	//opacity: 1 !important;
	//visibility: visible !important;
	transition: opacity .45s ease, visibility 0s ease .45s;
}

.fancybox-slide--iframe .fancybox-close-small, .fancybox-button--fullscreen {
	display: none;
}

////////////////////////////////////////////////////////
.fancybox-bg {
	background-color: #15171E;
}

.fancybox-is-open .fancybox-bg {
	opacity: 0.9;
}

.fancybox-image {
	box-shadow: 0 0 4rem hsla(0, 0%, 0%, .5);
	border-radius: $border-radius;
}

////////////////////////////////////////////////////////
.fancybox-caption-wrap {
	padding: 3rem 2rem 0;
	background: linear-gradient(to bottom, transparent 0, rgba(0, 0, 0, .1) 20%, rgba(0, 0, 0, .2) 40%, rgba(0, 0, 0, .6) 80%, rgba(0, 0, 0, .8) 100%);
}

.fancybox-caption {
	padding: 2rem;
	line-height: $line-height-base;
	font-size: $font-size-base;
	font-family: $font-family-base;
	font-weight: 600;
	border-top: $border-width solid rgba(#fff, .5);
	text-shadow: 0 0 .5rem rgba(0, 0, 0, .5), 0 0 1rem rgba(0, 0, 0, .5), 0 0 1.5rem rgba(0, 0, 0, .5);
	transform: translateY(0.5rem);
	transition: transform .45s ease;
	
	p {
		margin-bottom: .5rem;
	}
	
	> *:first-child {
		margin-top: 0;
	}
	
	> *:last-child {
		margin-bottom: 0;
	}
	
}

.fancybox-show-caption .fancybox-caption {
	transform: none;
}

////////////////////////////////////////////////////////
.fancybox-infobar {
	left: 0;
	margin-left: 0;
}

.fancybox-infobar__body {
	line-height: 4.5rem;
	width: 4.5rem;
	height: 4.5rem;
	font-size: $font-size-base;
	font-family: $font-family-base;
	color: hsla(0, 0%, 100%, .75);
	background-color: transparent;
	text-shadow: 0 0 .5rem rgba(0, 0, 0, .5), 0 0 1rem rgba(0, 0, 0, .5), 0 0 1.5rem rgba(0, 0, 0, .5);
}

.fancybox-button--right, .fancybox-button--left {
	display: none;
}

////////////////////////////////////////////////////////
.fancybox-button {
	background-color: transparent !important;
}

.fancybox-button--close {
	height: 4.5rem;
	width: 4.5rem;
	
	&:hover:not([disabled]) {
		&::after, &::before {
			background-color: $site-header-nav-normal-color-hover;
			transition-delay: 0s;
		}
	}
	
}

.fancybox-button--close {
	&:before, &:after {
		content: '';
		display: inline-block;
		position: absolute;
		height: 2px;
		width: 3rem;
		top: 50%;
		left: 50%;
		margin-top: -2.5px;
		margin-left: -25px;
		transition: .45s ease;
		transition-property: opacity, background-color;
		transition-delay: .225s, 0s;
		background-color: $site-header-nav-normal-color;
		box-shadow: 0 0 .5rem rgba(0, 0, 0, .5), 0 0 1rem rgba(0, 0, 0, .5), 0 0 1.5rem rgba(0, 0, 0, .5);
	}
}

////////////////////////////////////////////////////////
.fancybox-arrow {
	width: 4.5rem;
	height: 4.5rem;
	margin: -2.25rem 0 0;
	color: $site-header-nav-normal-color;
	transition-property: opacity, transform, color;
	transition: .45s ease;
	opacity: 0;
	
	&:after, &:after {
		width: 100%;
		background-color: transparent;
		font-family: "Ionicons";
		font-size: 2.5rem;
		background-image: none;
		background-size: 0;
		height: 100%;
		line-height: 4.5rem;
		top: 0;
		text-shadow: 0 0 .5rem rgba(0, 0, 0, .5), 0 0 1rem rgba(0, 0, 0, .5), 0 0 1.5rem rgba(0, 0, 0, .5);
	}
	
	&:hover {
		color: $site-header-nav-normal-color-hover;
	}
	
	&:focus {
		outline: none;
	}
	
}

.fancybox-arrow--left {
	left: 2.5rem;
	transform: translateX(-0.5rem);
	
	&:after {
		content: "\f3d2";
	}
	
}

.fancybox-arrow--right {
	right: 2.5rem;
	transform: translateX(0.5rem);
	
	&:after {
		content: "\f3d3";
	}
	
}

.fancybox-show-nav .fancybox-arrow {
	opacity: 1;
	transform: none;
}
