.initialism {
	font-size: 1rem;
}

ol, ul {
	padding-left: 3rem;
}

ol ol, ul ul, ol ul, ul ol {
	padding-left: 3rem;
}

.list-inline-item {
	//margin-left: -5px;
	
	&:first-child {
		margin-left: 0;
	}
	
}

.list-group {
	margin-bottom: 1rem;
}

.list-group-item-heading {
	margin-bottom: .5rem;
}

.list-group-item-text {
	line-height: $line-height-base;
}

.list-colored {
	
	a {
		font-weight: 600;
		color: #5B6777;
		transition: .45s color ease;
		display: inline-block;
		
		&:hover {
			color: #3C444F;
		}
		
	}
	
	.list-colored-title {
		color: #373E49;
		font-weight: bold;
		margin-bottom: 0;
		
		&:hover {
			color: #2B2D37;
		}
		
	}
	
	> li {
		margin-bottom: 1.5rem;
		
		&:last-child {
			margin-bottom: 0;
		}
		
		> ul {
			margin-top: .5rem;
			padding-top: 1.5rem;
			border-top: $border-width solid $border-color;
			
			&.row {
				
				&:before, &:after {
					content: '';
					position: absolute;
					top: 0rem;
					background-color: #FFFFFF;
					width: 1rem;
					height: 3.5rem;
				}
				
				&:before {
					left: 0;
				}
				
				&:after {
					right: 0;
				}
				
				&.list-type-events li {
					margin-bottom: .5rem;
				}
				
			}
			
			li {
				margin-bottom: .25rem;
				
				&:last-child {
					margin-bottom: 0;
				}
				
				> ul {
					margin-top: .25rem;
				}
				
			}
			
		}
		
	}
	
}
