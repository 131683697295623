@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 1rem;
$hamburger-padding-y: 1.25rem;
$hamburger-layer-width: 3rem;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 8px;
$hamburger-layer-color: #FFFFFF;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-transition-duration: 0.9s;
$hamburger-transition-duration-delay: 0.95s;
$hamburger-transition-timing-function: ease;
$hamburger-hover-transition-duration: 0.45s;
$hamburger-hover-transition-timing-function: ease;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: true;
$hamburger-hover-filter: #FFFFFF;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
	//3dx,
	//3dx-r,
	//3dy,
	//3dy-r,
	//arrow,
	//arrow-r,
	//arrowalt,
	//arrowalt-r,
	//boring,
	//collapse,
	//collapse-r,
	//elastic,
	//elastic-r,
	//emphatic,
	//emphatic-r,
	//slider,
	//slider-r,
	//spring,
	//spring-r,
	//stand,
	//stand-r,
	//spin,
	//spin-r,
	squeeze
	//vortex,
	//vortex-r
);

// Base Hamburger (We need this)
// ==================================================
@import "components/css-hamburgers/_sass/hamburgers/base";

// Hamburger types
// ==================================================
//@import "components/css-hamburgers/_sass/hamburgers/types/3dx";
//@import "components/css-hamburgers/_sass/hamburgers/types/3dx-r";
//@import "components/css-hamburgers/_sass/hamburgers/types/3dy";
//@import "components/css-hamburgers/_sass/hamburgers/types/3dy-r";
//@import "components/css-hamburgers/_sass/hamburgers/types/arrow";
//@import "components/css-hamburgers/_sass/hamburgers/types/arrow-r";
//@import "components/css-hamburgers/_sass/hamburgers/types/arrowalt";
//@import "components/css-hamburgers/_sass/hamburgers/types/arrowalt-r";
//@import "components/css-hamburgers/_sass/hamburgers/types/boring";
//@import "components/css-hamburgers/_sass/hamburgers/types/collapse";
//@import "components/css-hamburgers/_sass/hamburgers/types/collapse-r";
//@import "components/css-hamburgers/_sass/hamburgers/types/elastic";
//@import "components/css-hamburgers/_sass/hamburgers/types/elastic-r";
//@import "components/css-hamburgers/_sass/hamburgers/types/emphatic";
//@import "components/css-hamburgers/_sass/hamburgers/types/emphatic-r";
//@import "components/css-hamburgers/_sass/hamburgers/types/slider";
//@import "components/css-hamburgers/_sass/hamburgers/types/slider-r";
//@import "components/css-hamburgers/_sass/hamburgers/types/spring";
//@import "components/css-hamburgers/_sass/hamburgers/types/spring-r";
//@import "components/css-hamburgers/_sass/hamburgers/types/stand";
//@import "components/css-hamburgers/_sass/hamburgers/types/stand-r";
//@import "components/css-hamburgers/_sass/hamburgers/types/spin";
//@import "components/css-hamburgers/_sass/hamburgers/types/spin-r";
@import "components/css-hamburgers/_sass/hamburgers/types/squeeze";
//@import "components/css-hamburgers/_sass/hamburgers/types/vortex";
//@import "components/css-hamburgers/_sass/hamburgers/types/vortex-r";
