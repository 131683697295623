.page-overlay {
	background-color: rgba(0, 0, 0, .5);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	pointer-events: none;
	opacity: 0;
	z-index: 5;
	transition: opacity, visibility;
	transition-timing-function: ease;
	transition-duration: .9s;
	visibility: hidden;
	
	&.is-active {
		pointer-events: auto;
		opacity: 1;
		visibility: visible;
		cursor: pointer;
		
		@media (min-width: 992px) {
			opacity: 0;
			visibility: hidden;
		}
		
	}
	
}

body.is-active {
	@media (max-width: 991px) {
		overflow: hidden;
	}
}
