/*!--------------------------------------------------------------------
STYLES "Outdated Browser"
Version:    1.1.2 - 2015
author:     Burocratik
website:    http://www.burocratik.com
* @preserve
-----------------------------------------------------------------------*/
#outdated {
	display: none;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	text-transform: uppercase;
	z-index: 15000;
	background-color: rgba($brand-danger, .95);
	color: #FFFFFF;
	
	h6 {
		font-size: $font-size-h3;
		line-height: 3rem;
		margin: 2rem 0 .5rem;
		
		@media (max-width: 991px) {
			font-size: (35px / $font-size-root) + rem;
			line-height: 2.5rem;
		}
		
	}
	
	p {
		font-size: 1rem;
		line-height: 1.5rem;
		margin: 0;
		width: 100%;
	}
	
	#btnUpdateBrowser {
		display: block;
		position: relative;
		padding: .5rem 1rem;
		margin: 1.5rem auto 2rem;
		*width: 300px;
		max-width: 300px;
		color: #FFFFFF;
		text-decoration: none;
		border: $border-width solid #FFFFFF;
		border-radius: $btn-border-radius-sm;
		cursor: pointer;
		font-size: 1rem;
		font-weight: 400;
		line-height: $line-height-base;
		transition: background-color, color;
		transition-duration: .45s;
		transition-timing-function: ease;
		
		&:hover {
			color: #F25648;
			background-color: #FFFFFF;
		}
		
	}
	
	.last {
		position: absolute;
		top: 1.5rem;
		right: 1.5rem;
		width: 1.5rem;
		height: 1.5rem;
		
		&[dir='rtl'] {
			right: auto !important;
			left: 1.5rem !important;
		}
		
	}
	
	#btnCloseUpdateBrowser {
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		text-decoration: none;
		color: #FFFFFF;
		font-size: $font-size-h2;
		line-height: 2.5rem;
		
		@media (max-width: 991px) {
			line-height: 2rem;
			font-size: (30px / $font-size-root) + rem;
		}
		
	}
	
	* html & {
		position: absolute;
	}
	
}
