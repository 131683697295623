@mixin page-section-floated {
	
	.container {
		
		> blockquote {
			padding-left: 2rem;
			padding-right: 2rem;
		}
		
		> blockquote.pullquote {
			padding-left: inherit;
			padding-right: inherit;
		}
		
	}
	
	figure {
		
		+ figcaption {
			
			img {
				margin-bottom: 1rem;
				
				@media (max-width: 767px) {
					margin-bottom: .5rem;
				}
				
			}
			
		}
		
		figcaption {
			
			> *:last-child {
				margin-bottom: 0;
			}
			
			> *:first-child {
				margin-top: 0;
			}
			
		}
		
	}
	
	.floated-section {
		display: block;
		width: 100%;
		position: relative;
		
		&.breakout {
			display: block;
		}
		
	}
	
	.floated {
		
		@media (min-width: 768px) {
			width: 50%;
			display: inline-block;
			position: relative;
			vertical-align: middle;
		}
		
		&.push {
			
			figcaption {
				padding-bottom: 0;
			}
			
		}
		
		&.breakout {
			float: left;
			display: block;
			vertical-align: top;
			
			&.push {
				float: right;
			}
			
		}
		
		&.text {
			padding-right: 1rem;
			
			@media (max-width: 767px) {
				padding-right: 0;
			}
			
			&.push {
				padding-right: 0;
				padding-left: 1rem;
				
				@media (max-width: 767px) {
					padding-left: 0;
				}
				
			}
			
		}
		
		&.pullQuote {
			padding-right: 1rem;
			
			@media (max-width: 767px) {
				padding-right: 0;
			}
			
			&.push {
				margin-bottom: 0;
			}
			
			&.breakout {
				padding-right: 2rem;
				padding-bottom: 2rem;
				
				@media (min-width: 1200px) {
					width: 41.666667%;
				}
				
				&.push {
					padding-left: 2rem;
					padding-bottom: 2rem;
					
					@media (max-width: 767px) {
						padding-left: 0;
						padding-bottom: 1rem;
					}
					
				}
				
			}
			
			&.push {
				padding-left: 1rem;
				padding-right: 0;
				padding-bottom: 0;
				
				@media (max-width: 767px) {
					padding-left: 0;
				}
				
			}
			
		}
		
		&.image, &.youtubeVideo {
			padding-right: 1rem;
			
			@media (max-width: 767px) {
				padding-bottom: 1rem;
			}
			
			&.breakout {
				padding-right: 2rem;
				padding-bottom: 2rem;
				
				@media (min-width: 1200px) {
					width: 41.666667%;
				}
				
				@media (max-width: 767px) {
					width: 100%;
					padding-bottom: 1rem;
					padding-right: 0;
				}
				
				&.push {
					
					@media (min-width: 768px) {
						padding-right: 0;
						padding-left: 2rem;
					}
					
					@media (max-width: 767px) {
						padding-left: 0;
					}
					
					@media (min-width: 1264px) {
						//margin-right: -2rem;
					}
					
				}
				
			}
			//width: 41.666667%;
			&.push {
				
				@media (min-width: 768px) {
					padding-left: 1rem;
					padding-right: 0;
				}
				
				@media (max-width: 767px) {
					padding-top: 1rem;
					padding-right: 0;
				}
				
			}
			
		}
		
		blockquote {
			
			&.blockquote-reverse {
				padding-right: 2rem;
			}
			
		}
		
		&.breakout {
			
			@media (min-width: 1264px) {
				&:not(.push) {
					//margin-left: -2rem;
				}
			}
			
			p {
				display: table;
			}
			
			figure {
				margin-bottom: 2rem;
				height: inherit;
				
				@media (max-width: 767px) {
					margin-bottom: 0;
				}
				
			}
			
			figcaption {
				padding-bottom: 0;
			}
			
		}
		
	}
	
}
