@mixin page-section-people {
	&.ourPeople {
		
		.card-image-header-content a {
			word-wrap: break-word;
		}
		
		.text-white {
			a {
				color: rgba(255, 255, 255, .75);
				transition: color .45s ease;
				
				&:hover {
					color: #FFFFFF;
				}
				
			}
		}
		
		.position-item {
			
			&.col-md-6 {
				.people-item {
					
					@media (max-width: 1199px) {
						flex: 0 0 100%;
						max-width: 100%;
					}
					
					@media (min-width: 650px) and (max-width: 767px) {
						.card-image-header:before {
							padding-top: 50%;
						}
					}
					
				}
			}
			
			&.position-width {
				flex: 0 0 100%;
				max-width: 100%;
				
				.people-item {
					
					@media (min-width: 1200px) {
						flex: 0 0 33.33333%;
						max-width: 33.33333%;
					}
					
					@media (min-width: 768px) and (max-width: 1199px) {
						flex: 0 0 50%;
						max-width: 50%;
					}
					
				}
				
			}
			
		}
		
		.people-item {
			
			@media (min-width: 650px) and (max-width: 767px) {
				flex: 0 0 50%;
				max-width: 50%;
			}
			
			@media (min-width: 450px) and (max-width: 649px) {
				.card-image-header:before {
					padding-top: 70%;
				}
			}
			
			@media (min-width: 326px) and (max-width: 449px) {
				.card-image-header:before {
					padding-top: 100%;
				}
			}
			
		}
		
	}
}
