.nav-pills, .nav-tabs {
	margin-bottom: 1rem;
}

.nav-tabs .nav-link {
	padding: .625rem 1rem;
}

.nav-pills .nav-link {
	padding: .75rem 1rem;
}

.nav-pills .nav-item + .nav-item, .nav-tabs .nav-item + .nav-item {
	margin-left: .5rem;
}

.nav-stacked .nav-item + .nav-item {
	margin-top: 0;
	margin-left: 0;
}
