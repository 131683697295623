p[class*="bg-"] {
	padding: .5rem 1rem;
	color: $brand-text-color;
}

.white {
	background-color: #FFFFFF;
}

.bordered {
	background-color: #FFFFFF;
	border-bottom: $border-width solid $border-color;
}

.black, .dark, .brandPrimary, .brandSuccess, .brandInfo, .brandWarning, .brandDanger, .brandDark {
	color: #FFFFFF;
}

.black {
	background-color: #000000;
}

.bg-tras {
	background-color: transparent !important;
}

.text-white-light {
	color: rgba(255, 255, 255, .75);
	
	p {
		transition: color .45s ease;
	}
	
	a {
		color: rgba(255, 255, 255, .75);
		transition: color .45s ease;
		
		&:hover {
			color: #FFFFFF;
		}
		
	}
	
}

.tinted {
	background-color: $sidebar-background-color;
	color: #3C444F;
	
	a {
		color: #373E49;
		
		&:hover {
			color: #2B2D37;
		}
		
		&.btn-outline-primary {
			color: $brand-primary;
			
			&:hover {
				color: #FFFFFF;
			}
			
		}
		
	}
	
}

.brandPrimary {
	background-color: $brand-primary-hover;
}

.brandSuccess {
	background-color: $brand-success;
}

.brandInfo {
	background-color: $brand-info;
}

.brandWarning {
	background-color: $brand-warning;
}

.brandDanger {
	background-color: $brand-danger;
}

.brandDark {
	background-color: $brand-default;
}

.dark {
	background: #494A50;
	color: #FFFFFF;
}

a:focus, button:focus {
	outline-color: $brand-primary !important;
	
	//outline-color: $yellow !important;
	//background-color: $yellow !important;
	//color: $text-color !important;
}
